@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  background: #f0f0f0;
  color: black;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

a {
  color: black;
}

a {
  pointer-events: all;
  color: black;
  text-decoration: none;
}

svg {
  fill: black;
}


@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

canvas {
  opacity: 0;
  touch-action: none;
  animation: fade-in 5s ease 0.5s forwards;
}




.frame {
  width: 100%;
  min-height: 10vh;
  padding-bottom: 1.5rem;

}

.frame>* {
  margin: 1rem 1.5rem;
}

.frame.container {
  margin-top: 1em;
  position: fixed;
  z-index: 100;
}

.frame a,
.frame button {
  pointer-events: auto;
}

.frame a:not(.frame__title-back) {
  white-space: nowrap;
  /* overflow: hidden; */
  position: relative;
  display: inline;
}


.frame__title {
  grid-area: title;
  display: flex;
}

.frame__title-main {
  font-size: inherit;
  margin: 2.3em 1em 0;
  font-weight: inherit;
}

.frame__title-back {
  position: relative;
  display: flex;
  align-items: flex-end;
}


.frame__title-back svg {
  fill: currentColor;
}

.frame__title-logo {
  width: 6.5em;

  border-radius: 5px;
  margin-top: 1em;
}

.topnav {
  grid-area: prev;

}

.topnav a {
  padding: 0px 6px;
  float: left;
}

.dropdown {
  position: relative;
  margin-top: 2em;
  /* margin-right: 6.5em; */
}

.dropbtn {
  position: relative;
  border-radius: 48px;
  font-size: 20px;
  color: var(--color-link);
  font-weight: 500;
  background-color: #ffffff;
  color: #050801;
  z-index: 1;
  padding: 10px 30px 10px 30px;
  text-decoration: none;
  border: transparent;
  font-size: 15px;
  transition: 0.3s;

}

.dropbtn:hover {
  text-decoration: none;
  background: #cfcfcf;
  color: #ffffff;
}

.menu {
  position: fixed;
  z-index: 5;
  /* margin-right: 45px; */
  padding-left: max(1.2rem, 35/1440 * 100vw);
  padding-right: max(1.2rem, 35/1440 * 100vw);
}

.menu>li {
  margin: 2px;
  border-radius: 48px;
  transition: 0.3s;
  background-color: white;
  list-style: none;
  width: 120px;
  color: #050801;

}

.menu>li:hover {
  background-color: lightgray;
}

.menu>li>button {
  width: 100%;
  height: 100%;
  text-align: center;
  background: none;
  color: inherit;
  border: none;
  padding: 5px;
  margin: 0;
  font: inherit;
  cursor: pointer;
}

.linkButton {
  position: relative;
  display: inline-block;
  padding: 13px 15px;
  margin: 1px 0;
  color: black;
  font-size: smaller;
  text-decoration: none;
  text-transform: uppercase;
  transition: 0.5s;
  letter-spacing: 1px;
  overflow: hidden;
  margin-right: 10px;
  text-wrap: nowrap;
  background-color: white;
  border-radius: 20px;
  animation: GlowAnimation 1s infinite;
}

.linkButton:hover {
  background: #03e9f4;
  color: #050801;
  animation: none;
  box-shadow: 0 0 5px #03e9f4, 0 0 25px #03e9f4, 0 0 50px #03e9f4, 0 0 200px #03e9f4;
  -webkit-box-reflect: below 1px linear-gradient(transparent, #0005);
}

.hoverlightscreen {
  cursor: pointer;
}

.backButton {
  position: absolute;
  color: #ffffff;
  bottom: 4%;
  left: 45%;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.footerLeft {
  position: absolute;
  bottom: 5.5%;
  left: 4%;
  font-size: 13px;
  color: #ffffff;
}

.footerRight {
  position: absolute;
  bottom: 6%;
  right: 1.5%;
  font-size: 13px;
  text-align: right;
  color: #ffffff;
}

.footerLogo {
  position: absolute;
  bottom: 4%;
  left: 0.5%;
  width: 4%;
}

.footer {
  position: absolute;
  line-height: 1.1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.frame__title-logo {
  width: 4.5em;

  border-radius: 5px;
  margin-top: 1em;
}

@media (min-width: 400px) {
  p {
    margin-left: 50%;
    width: max(200px, 22vw);
  }

}

/* 对于小屏幕（手机等），设置基本样式 */
@media screen and (max-width: 27em) {
  .footerLeft {
    display: none;
  }

  .frame>* {
    margin: 1rem 0.01rem;
  }

  .footerLeft {
    position: absolute;
    bottom: 6%;
    left: 18%;
    font-size: 10px;
  }

  .footerRight {
    position: absolute;
    bottom: 6%;
    right: 1%;
    font-size: 10px;
    text-align: right;
  }

  .footerLogo {
    position: absolute;
    bottom: 4%;
    left: 1%;
    width: 15%;
  }

  .backButton {
    position: fixed;
    margin-left: 1em;
    top: 12%;
    left: 0%;
  }

  .footer {
    font-size: 1px;
  }

  /* 这里是针对小屏幕的 CSS 样式 */
  .frame {
    width: 85%;
    height: 5vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    width: 100%;
    /* 将此行调整至 display: flex; 下 */
  }

  .frame__title-logo {
    width: 4.5em;
    /* height: 42%; */
    border-radius: 5px;
    margin-top: 1em;
  }

  .headerLayout {
    margin-top: 11em;
    margin-bottom: 11em;
    padding: 0;

  }

  .dropbtn {
    justify-content: end;
  }

  .menu {
    justify-content: space-between;
    right: 0.5%;
    /* justify-content:end; */
  }

}

/* 对于小屏幕（手机等），设置基本样式 */
@media screen and (min-width: 27.0625em) and (max-width: 48em) {

  /* 这里是针对小屏幕的 CSS 样式 */
  .backButton {
    position: fixed;
    margin-left: 1em;
    top: 12%;
    left: 0%;
  }

  .frame {
    width: 85%;
    height: 5vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    width: 100%;
    /* 将此行调整至 display: flex; 下 */
  }

  .frame__title-logo {
    width: 4.5em;

    border-radius: 5px;
    margin-top: 1em;
  }


  .dropbtn {
    justify-content: end;
  }

  .menu {
    justify-content: space-between;
    right: 30px;
    /* justify-content:end; */
  }



}

/* 对于中等屏幕（平板等），设置适当的样式 */
@media screen and (min-width: 48.0625em) and (max-width: 64em) {
  .backButton {
    position: fixed;
    margin-left: 1em;
    top: 12%;
    left: 0%;
  }

  .frame {
    width: 100%;
    height: 5vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 1.5rem;
    /* 将此行调整至 display: flex; 下 */
  }

  .frame__title-logo {
    width: 4.5em;

    border-radius: 5px;
    margin-top: 1em;
  }


  .menu {
    right: 00px;
  }


}

/* 对于大屏幕（桌面电脑等），设置适当的样式 */
@media screen and (min-width: 64em) {


  .frame {
    width: 100%;
    height: 5vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 1.5rem;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    /* 将此行调整至 display: flex; 下 */
  }

  .menu {
    right:0.1em;
  }

  .menu a {
    color: #ffffff;
  }


}

/* @media only screen and (orientation: portrait) {
   .containerHakimi {
       display: none; 
   }
   #warning-message {
       display: block; 
   }
}

@media only screen and (orientation: landscape) {
   .containerHakimi {
       display: block;
   }

   #warning-message {
       display: none; 
   }
   
} */